import React, { useState } from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { ContainerFullWidth, ContainerBodyWidth } from '../containers'
import { MdClose } from "react-icons/md";
import Img from "gatsby-image"

const AdPanel = styled(ContainerBodyWidth)`
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 1;
  padding: 0px 0px;
  width: 55vw;
  /* padding: 0px; */
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.theme.colors.pcBlue};
  /* background-color: ${(props) => props.theme.theme.bg.primary}; */
  background-color: ${(props) => props.theme.theme.colors.pcBlueLight};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* display: flex; */
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 4px;
  align-items: center;
  /* border-bottom: 2px solid ${props => props.theme.theme.colors.blue}; */

  &.hidden {
    display: none;
  }

  &:hover {}

  h3, p {
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }

  svg {
    color: ${props => props.theme.theme.colors.pcBlue};

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 700px) {
    width: 95vw;
    bottom: 2.5vw;
    right: 2.5vw;
    font-size: 0.8rem;
    grid-template-columns: 1fr 30px;
  }
`

const AdContent = styled.div`
  padding: 12px;

  h3, p {
    margin: 0;
    color: ${props => props.theme.theme.colors.text.primary};
  }

  h3 {
    font-size: 1rem;
    font-weight: 400;
  }

  p {
    font-size: 0.9rem;
    color: ${props => props.theme.theme.colors.text.tertiary};
  }
`

const Highlight = styled.span`
    background-color: ${(props) => props.theme.theme.colors.pcBlueTransparent};
    padding: 4px;
    border-radius: 4px;
`

const Wordmark = styled.span`
  font-weight: 600;
  border-bottom: 2px solid #04CFFF;

  &:hover {
    }
`

export const PersonalCapitalFloatingAd = () => {
  const [isVisible, setIsVisible] = useState(true)

  return(
    <AdPanel className={isVisible ? `` : `hidden`} >
        <a href="https://personalcapital.sjv.io/ORaRvz" target="_blank">
          <AdContent>
            <h3>
              <Wordmark>Personal Capital</Wordmark> is the ultimate <Highlight>free</Highlight> personal finance dashboard app.
            </h3>

            <p>
              Get a 360 degree, real time view of your money and manage your entire financial life
              all in one place.
          </p>
          </AdContent>
      </a>
      <MdClose size="20px" onClick={() => setIsVisible(false)} />
    </AdPanel>
  )
}
