import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

function SEO ({description, lang, meta, keywords, title, image, imageUrl}) {
  return (
    <Helmet
    htmlAttributes={{lang}}
    title={'WalletBurst | ' + title}
    // titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
    >
      <script
        src={'/iframeResizer.contentWindow.min.js'}
      >
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO
